import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "questionaireForm" ]

  connect() {
    document.querySelectorAll('div.card-action input[data-text=true]').forEach((element) => {
      if (element.checked) {
        this.toggleTextAnswer(element.dataset.textId, true)
      }
    })
  }

  // Event listener for when a user clicks a check box or a radio button.
  // It will toggle the matching text answer field.
  choose(e) {
    const target = e.target;

    if (target == null) return;
    // For multi choice questions only toggle when text is true,
    // otherwise early return.
    if (target.dataset.multi && target.dataset.text != 'true') return;

    this.toggleTextAnswer(
      target.dataset.textId,
      target.checked && target.dataset.text == 'true'
    )
  }

  toggleTextAnswer(textId, show) {
    const textElement = document.getElementById(textId);
    const wrapperElement = textElement.closest('.text-toggle')

    if (wrapperElement == null) return;

    if (show) {
      wrapperElement.classList.remove('hide');
    }
    else {
      wrapperElement.classList.add('hide');
    }
  }

  finish(e) {
    this.questionaireFormTarget.submit();
  }
}
