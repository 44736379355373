// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import 'controllers';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

///////////////////////////////////
// Setup MaterializeCSS integration
import M from 'materialize-css/dist/js/materialize';
import AutoToast from './autotoast';

document.addEventListener('DOMContentLoaded', () => {
  M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), {
  	coverTrigger: false,
  	constrainWidth: false
  });
  M.FormSelect.init(document.querySelectorAll('select'), {
  });
  M.Modal.init(document.querySelectorAll('.modal'), {
  });
  M.Sidenav.init(document.querySelectorAll('.sidenav'), {
  });
  new AutoToast(window.Ratepro.flash);
});

/////////////////////////
// Setup Sentry reporting
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://6eeeb99e0529496c9333982ca4671478@o487576.ingest.sentry.io/5546502',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});
