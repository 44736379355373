import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['star', 'ratingValueInput', 'ratingDisplaySpan']
  static classes = ['inactiveStar', 'activeStar']

  connect() {
    this.enableTooltips();
    this.colorStars();
  }

  enableTooltips() {
    M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
  }

  colorStars() {
    const value = this.ratingValueInputTarget.value;

    this.starTargets.forEach((star) => {
      if (star.dataset.i <= value) {
        star.classList.add(this.activeStarClass)
        star.classList.remove(this.inactiveStarClass)
      } else {
        star.classList.add(this.inactiveStarClass)
        star.classList.remove(this.activeStarClass)
      }
    });
  }

  rate(e) {
    const starValue = e.currentTarget.dataset.i;

    this.ratingValueInputTarget.value = starValue;
    this.ratingDisplaySpanTarget.innerHTML = (parseInt(starValue) ? starValue : '... ') + ' / 20';

    this.colorStars();
  }

  showRatingInstruction(e) {
    const tooltip = M.Tooltip.init(e.target, {})
    tooltip.open();
    setTimeout(() => { tooltip.close(); }, 2000);
  }

  hideRatingInstruction(e) {
    const tooltip = M.Tooltip.getInstance(e.target);
    setTimeout(() => { tooltip.destroy(); }, 1000);
  }
}
