export default class AutoToast {
  constructor(flash) {
    this.flash = flash;
    this.display();
  }

  display() {
    if (this.flash.notice) {
      M.toast({html: this.flash.notice});
    }

    if (this.flash.alert) {
      M.toast({html: this.flash.alert});
    }
  }

  static write() {
    document.body.append("Hello World!")
  }
}
