import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'card',
    'starOverlayDiv',
    'professorOverlayDiv',
    'clearButton',
    'professorSelect',
    'notAttendingCheckBox',
    'star',
    'ratingValueInput'
  ]

  connect() {
    this.handleDoNotAttend();
    this.handleProfessorSelect();
  }

  // To clear the selected stars, trigger a click on the 0th hidden stars
  clear(e) {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }

    this.starTargets.forEach((star, i) => {
      if (star.dataset.i == '00') {
        star.click();
      }
    });
  }

  handleDoNotAttend(e) {
    const checked = this.hasNotAttendingCheckBoxTarget && this.notAttendingCheckBoxTarget.checked;

    if (checked) this.clear();

    if (this.hasProfessorSelectTarget) {
      this.toggleOverlay(this.professorOverlayDivTarget, checked);
      this.toggleOverlay(this.starOverlayDivTarget, checked || !this.professorSelectTarget.value);
    } else {
      this.toggleOverlay(this.starOverlayDivTarget, checked);
    }
  }

  handleProfessorSelect(e) {
    if (!this.hasProfessorSelectTarget) return;

    if (this.professorSelectTarget.disabled) {
      this.flashRed(this.notAttendingCheckBoxTarget.closest('p'));
    }

    this.toggleOverlay(this.starOverlayDivTarget, !this.professorSelectTarget.value);

    if (!this.professorSelectTarget.value) this.clear();
  }

  toggleOverlay(overlay, show) {
    if (show) {
      overlay.classList.add('disabled');
    } else {
      overlay.classList.remove('disabled');
    }
  }

  overlayClicked() {
    let wrapper = null;

    const professorB = this.hasProfessorSelectTarget && !this.hasProfessorSelectTarget.value;
    const checkboxB = this.hasNotAttendingCheckBoxTarget && this.notAttendingCheckBoxTarget.checked;

    if (checkboxB) {
      wrapper = this.notAttendingCheckBoxTarget.closest('p');
    } else if (professorB) {
      wrapper = this.professorSelectTarget.closest('p.professor');
    }

    this.flashRed(wrapper);
  }

  professorOverlayClicked(e) {
    if (e) this.overlayClicked();
  }

  starOverlayClicked(e) {
    if (e) this.overlayClicked();
  }

  flashRed(element) {
    element.classList.add('focused');
    setTimeout(() => { element.classList.remove('focused'); }, 500);
  }
}
